import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import NoResult from '../assets/images/noresult.jfif'
import ChartModal from './ChartModal';
import { ChevronDown, BarChartIcon, SquareGanttChart, FolderDown, SquareGanttChartIcon} from 'lucide-react';
import { NumericFormat } from 'react-number-format';
import AsyncSelect from 'react-select/async';

function ProductTable({ data }) {
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showMenu, setShowMenu] = useState(null);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
  const [searchTerm, setSearchTerm] = useState('');
  const [sortOption, setSortOption] = useState('');
  const navigate = useNavigate();

  const sortOptions = [
    { value: '', label: 'Sort by' },
    { value: 'name-asc', label: 'Product Name (A-Z)' },
    { value: 'name-desc', label: 'Product Name (Z-A)' },
    { value: 'wac-asc', label: 'WAC (Low to High)' },
    { value: 'wac-desc', label: 'WAC (High to Low)' },
  ];

  const handleViewChart = (product) => {
    setSelectedProduct(product);
    setShowModal(true);
  };

  const handleViewReport = (product) => {
    navigate('/report', { state: { product_id: product.product_id, data: product } });
  };

  // Function to convert data to CSV and trigger download
  const exportToCSV = () => {
    const csvRows = [];
    const headers = ['Product Name', 'Product ID', 'Cumulative QTY', 'Cumulative Cost', 'WAC'];

    csvRows.push(headers.join(','));

    sortedData.forEach(product => {
      const row = [
        product.product_name,
        product.product_id,
        product.cumulative_quantity,
        product.cumulative_cost,
        product.wac,
      ];
      csvRows.push(row.join(','));
    });

    const csvString = csvRows.join('\n');
    const blob = new Blob([csvString], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    
    // Format the current date
    const date = new Date().toISOString().split('T')[0]; // YYYY-MM-DD format
    const a = document.createElement('a');
    a.href = url;
    a.download = `WAC_Report_${date}.csv`; // Use the formatted date in the filename
    a.click();
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.dropdown-menu')) {
        setShowMenu(null);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const filteredData = data?.results?.filter((product) => {
    const lowercasedSearch = searchTerm.toLowerCase();
    return (
      product.product_name.toLowerCase().includes(lowercasedSearch) ||
      product.product_id.toString().includes(lowercasedSearch)
    );
  });

  const sortedData = [...(filteredData || [])].sort((a, b) => {
    switch (sortOption) {
      case 'name-asc':
        return a.product_name.localeCompare(b.product_name);
      case 'name-desc':
        return b.product_name.localeCompare(a.product_name);
      case 'wac-asc':
        return a.wac - b.wac;
      case 'wac-desc':
        return b.wac - a.wac;
      default:
        return 0;
    }
  });
const noData=true
  return (
    <div className=''>
         <table className="min-w-full text-gray-900 md:table shadow border bg-white">
                <thead>
                  <tr className="font-normal text-sm text-[#706f6f]">
                    <th className="border-b border-r text-center py-2 pl-4">
                      Purchase Order
                    </th>
                    <th className="border-b border-r md:py-2 pl-4">
                      Receive Goods
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="border border-gray-200">
                      <table className="min-w-[1024px] w-full">
                        <thead className="text-[#545454] text-sm border-b border-gray-200 my-3">
                          <tr>
                            <td className="py-2 text-sm font-medium px-2 border-r border-gray-200">Description</td>
                            <td className="py-2 text-sm font-medium px-2 border-r border-gray-200">Product Code</td>
                            <td className="py-2 text-sm font-medium px-2 border-r border-gray-200">UoM</td>
                            <td className="py-2 text-sm font-medium text-right pr-4 border-r border-gray-200">Outstanding</td>
                            <td className="py-2 text-sm font-medium text-right pr-3 border-r border-gray-200">Unit Price</td>
                            <td className="py-2 text-sm font-medium text-right pr-4 border-r border-gray-200">Amount</td>
                            <td className="py-2 text-sm font-medium text-right pr-4 border-r border-gray-200">Tax</td>
                            <td className="py-2 text-sm font-medium text-right pr-2 border-l border-gray-200">Discount</td>
                          </tr>
                        </thead>
                        <tbody>
                            <tr  className="text-sm leading-7 h-12  border-gray-200">
                              <td className="py-2 text-sm px-2 border-r border-gray-200">
                               Description
                              </td>
                              <td className="py-2 text-sm px-2 border-r border-gray-200">mmm129</td>
                              <td className="py-2 text-sm px-2 border-r border-gray-200">
                                KGM
                              </td>
                              <td className="py-2 text-right pr-4 border-r border-gray-200">
                               50000.92
                              </td>
                              <td className="py-2 text-sm text-right pr-3 border-r border-gray-200">
                              300
                              </td>
                              <td className="text-right pr-4 py-2 text-sm border-r border-gray-200">
                               100,000.000
                              </td>
                              <td className="text-right pr-4 py-2 text-sm border-r border-gray-200">
                               700,000
                              </td>
                              <td className="text-right pr-2 py-2 text-sm border-l border-gray-200">
                               500,000
                              </td>
                            </tr>
                          
                        </tbody>
                      </table>
                    </td>
                    <td className="overflow-y-auto">
                      <table className="w-full">
                        <thead className="text-[#545454] font-medium text-sm border-b border-gray-200">
                          <tr>
                            <td className="py-2 md:py-2 text-sm font-medium pl-4 border-r border-gray-200">Quantity</td>
                            <td className="py-2 text-sm text-right font-medium pr-2 px-2 border-r border-gray-200">Amount</td>
                          </tr>
                        </thead>
                        <tbody>
                            <tr  className="text-sm leading-7 h-12 border-b border-gray-200">
                              <td className="py-1 px-4 border-r border-gray">
                                
                                    
                                      <div className="">
                                        <input
                                          type="text"
                                         
                                          
                                          className={`border h-8 text-right pr-4 text-sm w-28 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 appearance-none mx-3 `}
                                          value=''
                                         
                                        />
                                       
                                      </div>
                                    
                                  
                              </td>
                              <td className="text-right px-2 pr-2 py-2 text-sm border-r border-gray-200">
                                200000
                              </td>
                            </tr>
                          
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-gray-200">
                      <table className="min-w-[1024px] w-full">
                        <thead className="text-[#545454] text-sm border-b border-gray-200 my-3">
                          <tr>
                            <td className="py-2 text-sm font-medium px-2 border-r border-gray-200">Description</td>
                            <td className="py-2 text-sm font-medium px-2 border-r border-gray-200">Product Code</td>
                            <td className="py-2 text-sm font-medium px-2 border-r border-gray-200">UoM</td>
                            <td className="py-2 text-sm font-medium text-right pr-4 border-r border-gray-200">Outstanding</td>
                            <td className="py-2 text-sm font-medium text-right pr-3 border-r border-gray-200">Unit Price</td>
                            <td className="py-2 text-sm font-medium text-right pr-4 border-r border-gray-200">Amount</td>
                            <td className="py-2 text-sm font-medium text-right pr-4 border-r border-gray-200">Tax</td>
                            <td className="py-2 text-sm font-medium text-right pr-2 border-l border-gray-200">Discount</td>
                          </tr>
                        </thead>
                        <tbody>
                            <tr  className="text-sm leading-7 h-12 border-b border-gray-200">
                              <td className="py-2 text-sm px-2 border border-gray-200">
                               Description
                              </td>
                              <td className="py-2 text-sm px-2 border border-gray-200">mmm129</td>
                              <td className="py-2 text-sm px-2 border border-gray-200">
                                KGM
                              </td>
                              <td className="py-2 text-right pr-4 border border-gray-200">
                               50000.92
                              </td>
                              <td className="py-2 text-sm text-right pr-3 border border-gray-200">
                              300
                              </td>
                              <td className="text-right pr-4 py-2 text-sm border border-gray-200">
                               100,000.000
                              </td>
                              <td className="text-right pr-4 py-2 text-sm border border-gray-200">
                               700,000
                              </td>
                              <td className="text-right pr-2 py-2 text-sm border-l border-gray-200">
                               500,000
                              </td>
                            </tr>
                          
                        </tbody>
                      </table>
                    </td>
                    <td className="overflow-y-auto">
                      <table className="w-full">
                        <thead className="text-[#545454] font-medium text-sm border-b border-gray-200">
                          <tr>
                            <td className="py-2 md:py-2 text-sm font-medium pl-4">Quantity</td>
                            <td className="py-2 text-sm text-right font-medium pr-2 px-2 border border-gray-200">Amount</td>
                          </tr>
                        </thead>
                        <tbody>
                            <tr  className="text-sm leading-7 h-12 border-b border-gray-200">
                              <td className="py-1 px-4 border-r border-gray">
                                
                                    
                                      <div className="">
                                        <input
                                          type="text"
                                         
                                          
                                          className={`border h-8 text-right pr-4 text-sm w-28 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 appearance-none mx-3 `}
                                          value=''
                                         
                                        />
                                       
                                      </div>
                                    
                                  
                              </td>
                              <td className="text-right px-2 pr-2 py-2 text-sm border-r border-gray-200">
                                200000
                              </td>
                            </tr>
                          
                        </tbody>
                      </table>
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-gray-200">
                      <table className="min-w-[1024px] w-full">
                        <thead className="text-[#545454] text-sm border-b border-gray-200 my-3">
                          <tr>
                            <td className="py-2 text-sm font-medium px-2 border-r border-gray-200">Description</td>
                            <td className="py-2 text-sm font-medium px-2 border-r border-gray-200">Product Code</td>
                            <td className="py-2 text-sm font-medium px-2 border-r border-gray-200">UoM</td>
                            <td className="py-2 text-sm font-medium text-right pr-4 border-r border-gray-200">Outstanding</td>
                            <td className="py-2 text-sm font-medium text-right pr-3 border-r border-gray-200">Unit Price</td>
                            <td className="py-2 text-sm font-medium text-right pr-4 border-r border-gray-200">Amount</td>
                            <td className="py-2 text-sm font-medium text-right pr-4 border-r border-gray-200">Tax</td>
                            <td className="py-2 text-sm font-medium text-right pr-2 border-l border-gray-200">Discount</td>
                          </tr>
                        </thead>
                        <tbody>
                            <tr  className="text-sm leading-7 h-12 border-b border-gray-200">
                              <td className="py-2 text-sm px-2 border border-gray-200">
                               Description
                              </td>
                              <td className="py-2 text-sm px-2 border border-gray-200">mmm129</td>
                              <td className="py-2 text-sm px-2 border border-gray-200">
                                KGM
                              </td>
                              <td className="py-2 text-right pr-4 border border-gray-200">
                               50000.92
                              </td>
                              <td className="py-2 text-sm text-right pr-3 border border-gray-200">
                              300
                              </td>
                              <td className="text-right pr-4 py-2 text-sm border border-gray-200">
                               100,000.000
                              </td>
                              <td className="text-right pr-4 py-2 text-sm border border-gray-200">
                               700,000
                              </td>
                              <td className="text-right pr-2 py-2 text-sm border-l border-gray-200">
                               500,000
                              </td>
                            </tr>
                          
                        </tbody>
                      </table>
                    </td>
                    <td className="overflow-y-auto">
                      <table className="w-full">
                        <thead className="text-[#545454] font-medium text-sm border-b border-gray-200">
                          <tr>
                            <td className="py-2 md:py-2 text-sm font-medium pl-4">Quantity</td>
                            <td className="py-2 text-sm text-right font-medium pr-2 px-2 border border-gray-200">Amount</td>
                          </tr>
                        </thead>
                        <tbody>
                            <tr  className="text-sm leading-7 h-12 border-b border-gray-200">
                              <td className="py-1 px-4 border-r border-gray">
                                
                                    
                                      <div className="">
                                        <input
                                          type="text"
                                         
                                          
                                          className={`border h-8 text-right pr-4 text-sm w-28 rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 appearance-none mx-3 `}
                                          value=''
                                         
                                        />
                                       
                                      </div>
                                    
                                  
                              </td>
                              <td className="text-right px-2 pr-2 py-2 text-sm border-r border-gray-200">
                                200000
                              </td>
                            </tr>
                          
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table className="w-full">
                <tbody>
                  <tr className="flex justify-between w-full bg-blue-800">
                    <td className="px-3 py-2 text-white flex gap-32">
                      <span>Total PO Value</span>
                      <span>
                      200,000
                      </span>
                    </td>
                    <td className="px-3 py-2 text-white flex justify-between gap-32" colSpan="2">
                      <span>Total Received Goods</span>
                      <span>
                        100,000
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
    </div>
  );
}

export default ProductTable;
