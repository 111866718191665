import React, { useState, useEffect } from "react";
import { ReactComponent as ArrowLeft } from "../assets/images/arrowLeft.svg";
import axios from "axios";
import { format } from "date-fns";
import { NumericFormat } from "react-number-format";
const CreateInvoiceStep4 = ({
	handlePrevious,
	currentPage,
	totalPages,
	handleNext,
	vendorInfo,
	selectedPO,
}) => {
	const handleNextPage = () => {
		localStorage.setItem("ReferenceNumber", referenceNumber);
		localStorage.setItem("DueDate", format(new Date(), 'yyyy-MM-dd'));
		localStorage.setItem(
			"selectedItemswithduties",
			JSON.stringify(selectedItems)
			
		);
		setSelectedItems(selectedItems)
		handleNext();
	};
	const token = localStorage.getItem('access')
	const [referenceNumber, setReferenceNumber] = useState(null);
	const referenceNumberNull = referenceNumber && referenceNumber.length > 0;
	useEffect(() => {
		const savedReferenceNumber = localStorage.getItem('ReferenceNumber');
		if (savedReferenceNumber) {
		  setReferenceNumber(savedReferenceNumber);
		}
	  }, []);
	
	  const [errorMessage, setErrorMessage] = useState('');

	  const onReferenceNumberChanged = (event) => {
		const { value } = event.target;
	  
		// Regular expression to check if the input is a valid number or empty (for deletion)
		const isValidNumber = /^[0-9]*$/.test(value);
	  
		if (isValidNumber) {
		  setReferenceNumber(value);
		  setErrorMessage(''); // Clear error message if input is valid or empty
		} else {
		  setErrorMessage('Invalid input: Please enter a number.');
		}
	  };

	
	  useEffect(() => {
		localStorage.setItem('ReferenceNumber', referenceNumber?referenceNumber:'');
	  }, [referenceNumber]);
	
	  // Remove referenceNumber from local storage before page unloads
	  useEffect(() => {
		const clearLocalStorage = () => {
		  localStorage.removeItem('ReferenceNumber');
		};
	
		window.addEventListener('beforeunload', clearLocalStorage);
	
		return () => {
		  window.removeEventListener('beforeunload', clearLocalStorage);
		};
	  }, []);;
	const SelectedItems = JSON.parse(localStorage.getItem("SelectedItems")) || {
		filteredData: [],
	};
	const [selectedItems, setSelectedItems] = useState(
		SelectedItems.filteredData
	);
	
	  useEffect(() => {
		localStorage.setItem('selectedItems', JSON.stringify(selectedItems));
	  }, [selectedItems]);
	
	  // Remove selectedItems from local storage before page unloads
	  useEffect(() => {
		const clearLocalStorage = () => {
		  localStorage.removeItem('selectedItems');
		  localStorage.removeItem('selectedRows');
		};
	
		window.addEventListener('beforeunload', clearLocalStorage);
	
		return () => {
		  window.removeEventListener('beforeunload', clearLocalStorage);
		};
	  }, []);
	
	
	const [selectedRow, setSelectedRow] = useState(
		Array.from({ length: selectedItems.length }, () => [])
	);

	useEffect(() => {
		setSelectedRow(
			Array.from({ length: SelectedItems.filteredData.length }, () => [])
		);
	}, []);

	const [taxRates, setTaxRates] = useState([]);
	const isAnyDiscountInvalid = selectedItems.some(item => item.discountStatus && (!item.discountType|| !item.discountValue));

	const isAnyTaxInvalid = selectedItems.some(item => item.taxStatus && !item.selectedOptions);

	useEffect(() => {
		const fetchTaxRates = async () => {
			try {
				const response = await axios.get(
					`${process.env.REACT_APP_BASE_URL}/api/v1/surcharges`,
					{
						headers: {
						  Authorization: `Bearer ${token}`,
						},}
				
				)
				setTaxRates(
					response.data.data
						.map((item) => ({
							value: item.id,
							label: item.type,
							rate: item.rate,
						}))
						.filter((item) => item.rate > 0)
				);
			} catch (error) {
				console.error("Error fetching tax rates:", error);
			}
		};

		fetchTaxRates();
	}, []);
useEffect(()=>{
},[selectedItems])
	return (
		<div>
			<div className="pb-5 box-shadow bg-white flex flex-col">
				<div className="flex p-3 justify-between bg-blue-800 text-base text-white">
					<div className="flex gap-2">
						<button
							className="border-0 bg-transparent"
							onClick={handlePrevious}
						>
							<ArrowLeft />
						</button>
						<div className="flex">Input invoice reference number</div>
					</div>
					<span>
						Step {currentPage} of {totalPages}
					</span>
				</div>
				<div className="flex flex-col md:flex-row justify-between w-full md:px-12 mx-2 md:gap-8 gap-3 my-4 mb-2">
					<div className=" flex-col w-full md:self-center">
						<label htmlFor="vendor" className="mb-0  text-sm font-semibold">
							Vendor
						</label>
						<input
							type="text"
							id="vendor"
							value={vendorInfo}
							className="mt-2 block p-2 font-normal   text-sm  w-full px-3 border h-10  rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-600 appearance-none focus:border-2"
						/>
					</div>
					<div className="w-full flex-col items-center">
						<label htmlFor="vendor" className="mb-0   text-sm font-semibold">
							PO Number
						</label>
						<input
							type="text"
							id="vendor"
							value={selectedPO}
							className="mt-2 block p-2 font-normal  text-sm w-[95%] border h-10 mx-auto md:w-full rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-600 appearance-none focus:border-2"
						/>
					</div>
				</div>

				<div className="md:mx-12 mx-2 flex flex-col justify-center px-2 mt-5">
    {/* Responsive Table */}
    <div className="overflow-x-auto">
        <table className="w-full">
            <thead className="bg-blue-800 text-white font-normal">
                <tr>
                    <th className="py-2 pl-2">GRN Number</th>
                    <th className="py-2 pl-2">Item Description</th>
                    <th className="py-2 text-right pr-4">Quantity</th>
                    <th className="py-2 text-right pr-8">Amount</th>
                    <th className="py-2 text-right pr-8">Tax</th>
                    <th className="py-2 text-right pr-8">Discount</th>
                </tr>
            </thead>
            <tbody>
                {selectedItems.map((item, index) => (
                    <tr key={index}>
                        <td className="py-1">
                            <input
                                type="text"
                                value={item.grn_number}
                                readOnly
                                className="mt-2 block p-2 w-full md:w-32 h-10 font-normal border text-sm rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-600 appearance-none focus:border-2"
                            />
                        </td>
                        <td className="py-1">
                            <input
                                type="text"
                                value={item.purchase_order_line_item.product_name}
                                readOnly
                                className="mt-2 block p-2 w-full md:w-64 h-10 font-normal border text-sm rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-600 appearance-none focus:border-2"
                            />
                        </td>
                        <td className="py-1">
                            <NumericFormat
                                value={item.quantity_received}
                                displayType="input"
                                readOnly
                                thousandSeparator={true}
                                className="mt-2 text-right block w-full md:w-32 p-2 h-10 font-normal border text-sm rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-600 appearance-none focus:border-2"
                            />
                        </td>
                        <td className="py-1">
                            <NumericFormat
                                value={item.net_value_received || 0.00}
                                displayType="input"
                                readOnly
                                thousandSeparator={true}
                                className="mt-2 text-right block w-full md:w-40 p-2 h-10 font-normal border text-sm rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-600 appearance-none focus:border-2"
                            />
                        </td>
                        <td className="py-1">
                            <NumericFormat
                                value={
                                    item.purchase_order_line_item.metadata.TaxAmount
                                        ? parseFloat(item.purchase_order_line_item.metadata.TaxAmount)
                                        : 0.00
                                }
                                displayType="input"
                                readOnly
                                thousandSeparator={true}
                                className="mt-2 text-right block w-full md:w-40 p-2 h-10 font-normal border text-sm rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-600 appearance-none focus:border-2"
                            />
                        </td>
                        <td className="py-1">
                            <NumericFormat
                                value={
                                    item.purchase_order_line_item.metadata.DiscountAmount
                                        ? parseFloat(item.purchase_order_line_item.metadata.DiscountAmount)
                                        : 0.00
                                }
                                displayType="input"
                                readOnly
                                thousandSeparator={true}
                                className="mt-2 text-right block w-full md:w-40 p-2 h-10 font-normal border text-sm rounded-md focus:outline-none focus:ring-indigo-500 focus:border-blue-600 appearance-none focus:border-2"
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    </div>

    {/* Reference Number Section */}
    <div className="flex flex-col md:flex-row items-center w-full gap-4 my-3 px-4 py-12 bg-white">
        <label htmlFor="vendor" className="text-base w-full md:w-[20%]">
            Reference Number:
        </label>
        <div className="w-full">
            <input
                type="text"
                placeholder="Enter Invoice Reference Number"
                id="vendor"
                value={referenceNumber}
                onChange={onReferenceNumberChanged}
                className="mt-1 p-2 w-full shadow-none rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 border border-gray-700"
            />
            {errorMessage && (
                <p className="mt-1 text-sm text-red-600">{errorMessage}</p>
            )}
        </div>
    </div>

    {/* Button */}
    <button
        disabled={
            !referenceNumberNull || isAnyDiscountInvalid || isAnyTaxInvalid || errorMessage
        }
        onClick={handleNextPage}
        className={`md:self-end w-full px-4 mt-2 font-medium text-sm md:w-[10rem] py-2 border rounded-md ${
            (!referenceNumberNull || isAnyDiscountInvalid || isAnyTaxInvalid || errorMessage)
                ? "bg-gray-400 text-white cursor-not-allowed"
                : "bg-blue-500 text-white cursor-pointer"
        }`}
    >
        Preview Invoice
    </button>
</div>

			</div>
		</div>
	);
};

export default CreateInvoiceStep4;
