import React,{useEffect, useState} from 'react'
import {ReactComponent as Vector1 }from '../../assets/images/Group 1.svg'
import {ReactComponent as Vector3}from '../../assets/images/Group 1 (3).svg'
import {ReactComponent as Vector4 }from '../../assets/images/Group 1 (4).svg'
import {ReactComponent as ApprovedIcon }from '../../assets/images/approvedIcon.svg'
import { AgCharts } from "ag-charts-react";
import { NumericFormat } from 'react-number-format';
import axios from 'axios'
import Loader from '../../components/Loader'
import SessionExpired from './sessionExpired'
import { useNavigate } from 'react-router-dom'
import TableCommponnent from '../../components/TableCommponnent'
import Header from '../apprLyout/Topnav'
import Sidenav from '../apprLyout/Sidenav'
const Homepage = () => {

  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState('');
  const [count, setCount] = useState('');
  // const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [allInvoices, setAllInvoices] = useState([]);
  // const [invoiceData, setInvoiceData] = useState([]);
  const [pendingInvoices, setPendingInvoices] = useState([]);
  const [completedInvoices, setCompletedInvoices] = useState([]);
  const [allInvoicesCount, setAllInvoicesCount] = useState(0);
  const [pendingInvoicesCount, setPendingInvoicesCount] = useState(0);
  // const [completedInvoicesCount, setCompletedInvoicesCount] = useState(0);
  const [approvedInvoicesCount, setApprovedInvoicesCount] = useState(0);
  const [declinedInvoicesCount, setDeclinedInvoicesCount] = useState(0);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const navigate=useNavigate()
  const token = localStorage.getItem('accessToken');
  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const roles = JSON.parse(localStorage.getItem('roles')) || [];
        const isAdmin = roles.includes("dmd_ss");
  
        const allInvoicesPromise = axios.get(
          `${process.env.REACT_APP_BASE_URL}approvals/v1/${isAdmin ? 'any' : 'get'}/invoice/${isAdmin ? 'all' : 'all'}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const pendingInvoicesPromise = axios.get(
          `${process.env.REACT_APP_BASE_URL}approvals/v1/${isAdmin ? 'any' : 'get'}/invoice/pending`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const completedInvoicesPromise = axios.get(
          `${process.env.REACT_APP_BASE_URL}approvals/v1/${isAdmin ? 'any' : 'get'}/invoice/${isAdmin ? 'completed' : 'all?approved=1'}`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
        const declinedInvoicesPromise = axios.get(
          `${process.env.REACT_APP_BASE_URL}approvals/v1/get/invoice/all?approved=0`,
          { headers: { Authorization: `Bearer ${token}` } }
        );
  
        const [
          allInvoicesResponse,
          pendingInvoicesResponse,
          completedInvoicesResponse,
          declinedInvoicesResponse
        ] = await Promise.all([
          allInvoicesPromise,
          pendingInvoicesPromise,
          completedInvoicesPromise,
          declinedInvoicesPromise
        ]);
  
        // Process completed and declined invoices based on admin role
        if (isAdmin) {
          const filteredCompletedInvoices = completedInvoicesResponse.data.data.results.filter(
            (invoice) => invoice.workflow.completed === true && invoice.workflow.approved === true
          );
          setCompletedInvoices(filteredCompletedInvoices);
          setApprovedInvoicesCount(filteredCompletedInvoices.length);
          localStorage.setItem('completedInvoices', JSON.stringify(filteredCompletedInvoices));
  
          const filteredDeclinedInvoices = completedInvoicesResponse.data.data.results.filter(
            (invoice) => invoice.workflow.completed === true && invoice.workflow.approved === false
          );
          setDeclinedInvoicesCount(filteredDeclinedInvoices.length);
          console.log(filteredDeclinedInvoices, 'declined');
        } else {
          setCompletedInvoices(completedInvoicesResponse.data.data.results);
          setApprovedInvoicesCount(completedInvoicesResponse.data.data.results.length);
          localStorage.setItem('completedInvoices', JSON.stringify(completedInvoicesResponse.data.data.results));
  
          // Set declined invoices from the declinedInvoicesPromise response
          setDeclinedInvoicesCount(declinedInvoicesResponse.data.data.results.length);
          console.log(declinedInvoicesResponse.data.data.results, 'declined');
        }
  
        setAllInvoices(allInvoicesResponse.data.data.results);
        setAllInvoicesCount(allInvoicesResponse.data.data.results.length);
        localStorage.setItem('allInvoices', JSON.stringify(allInvoicesResponse.data.data.results));
  
        setPendingInvoices(pendingInvoicesResponse.data.data.results);
        setPendingInvoicesCount(pendingInvoicesResponse.data.data.results.length);
        localStorage.setItem('pendingInvoices', JSON.stringify(pendingInvoicesResponse.data.data.results));
  
      } catch (error) {
        if (token && error?.response?.status === 401) {
          setRefresh(true);
        } else {
          handleError(error);
        }
      } finally {
        setLoading(false);
      }
    };
  
    fetchData();
  }, [token]);

  const handleButtonClick = (item) => {
    console.log(item);
  };
  const handleError = (error) => {
    console.error("Invoice fetching error:", error);
    const errorMessage = error?.response?.data?.detail || 'Error Fetching Invoice Data';
    
    if (error?.response?.status === 401) {
      setRefresh(true);
      // handleLogout(msalConfig, userName, navigate); // Uncomment if needed
    } else {
      setError(errorMessage);
    }
  };


// Convert the grouped data object to an array
const groupedData = [...completedInvoices].reverse()
   // Only include approved items with a date_signed
  .map(item => ({
      ...item,
      month: new Date(item.workflow.signatures[0].date_signed).toLocaleString('default', { month: 'short' }) // Format month without year
  }))
  .reduce((acc, item) => {
      const month = item.month;
      if (!acc[month]) {
          acc[month] = { month, invoice_count: 0 };
      }
      acc[month].invoice_count += 1; // Increment the count for the month
      return acc;
  }, {});

// Convert the grouped data object to an array
const chartData = Object.values(groupedData);

// Create an array of all months till the current month
const allMonths = [];
const currentDate = new Date();
for (let m = 0; m <= currentDate.getMonth(); m++) {
    const monthName = new Date(0, m).toLocaleString('default', { month: 'short' });
    allMonths.push({ month: monthName, invoice_count: 0 });
}

// Fill in the chartData with invoice counts
const finalChartData = allMonths.map(month => {
    const found = chartData.find(data => data.month === month.month);
    return {
        month: month.month,
        invoice_count: found ? found.invoice_count : 0 // Use the count from chartData or 0 if not found
    };
});

//set  empty data if no approved invoices are found
const options = {
  data: finalChartData.some(item => item.invoice_count > 0) ? finalChartData : [], // Use empty data if no invoices
  series: [
      {
          xKey: 'month',
          yKey: 'invoice_count',
          yName: 'Number of Approved Invoices',
          type: 'bar', // Specify the chart type as bar
          barWidth: 30, // Set the width of each bar
      },
  ],
  axes: [
      {
          type: 'category',
          position: 'bottom',
      },
      {
          type: 'number',
          position: 'left',
      },
  ],
  legend: {
      enabled: true, // Enable the legend if needed
  },
  padding: {
      left: 40,
      right: 40,
  },
};

  return (
    <div className='min-h-screen'>
    {loading && <Loader/>}
   {refresh ?
  <SessionExpired/>: 
  <>
  <Header />

            <div className="flex main-body ">
              <div className=" hidden md:block">
                <Sidenav/>
              </div>
              <div className="content md:w-[80%] w-full md:ml-[20%] lg:w-[86%] lg:ml-[14%] p-3 mt-[70px]">
        <div className="  grid grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-3">
      <div className="bg-white border-t-2 border-[#b32f4e] rounded-lg p-3 cursor-pointer hover:bg-slate-50" onClick={()=>{navigate('/approval/all')}}>
            <Vector1/>
        <p className='my-3 text-base' >Treated Invoices</p>
        <p 
            className='criteria-value' >
            <NumericFormat 
            value={allInvoicesCount} 
            displayType={'text'} 
            thousandSeparator={true} 
            decimalSeparator="."  />
        </p>
      </div>
      <div className="bg-white border-t-2 border-[#33bc6c] rounded-lg p-3 cursor-pointer hover:bg-slate-50" onClick={()=>{navigate('/approval/approved')}}>
            {/* <Vector2/> */}
            <ApprovedIcon/>
        <p className='my-3 text-base' >Approved Invoices</p>
        <p 
            className='criteria-value' >
            <NumericFormat 
            value={approvedInvoicesCount } 
            displayType={'text'} 
            thousandSeparator={true} 
            decimalSeparator="."  />
        </p>
  </div>
     <div className="bg-white border-t-2 border-[#c86406] rounded-lg p-3 cursor-pointer hover:bg-slate-50" onClick={()=>{navigate('/approval/pending')}}>
            <Vector3/>
        <p className='my-3 text-base' >Pending Invoices</p>
        <p 
            className='criteria-value' >
            <NumericFormat 
            value={pendingInvoicesCount } 
            displayType={'text'} 
            thousandSeparator={true} 
            decimalSeparator="."  />
        </p>
     </div>
   <div className="bg-white rounded-lg border-t-2 border-[#e30f1d] p-3 " onClick={()=>{navigate('/approval/declined')}}>
            <Vector4/>
        <p className='my-3 text-base' >Declined Invoices</p>
        <p 
            className='criteria-value' >
            <NumericFormat 
            value={declinedInvoicesCount } 
            displayType={'text'} 
            thousandSeparator={true} 
            decimalSeparator="."  />
        </p>
   </div>
        </div>
        <div class="request-charts__container box-shadow bg-white my-4 px-4 rounded-lg">
          <p className='font-semibold text-base pt-4'>Invoice chart</p>
          <p className='text-[#696969] mt-2 mb-6 text-base'>Total Invoices approved over time</p>
          <AgCharts options={options}/>
          </div>
          <TableCommponnent 
          data={pendingInvoices}
          recent_invoice={true}
          onButtonClick={handleButtonClick}
          total={total}
          count={count}
          size={size}
          page={page}
          
          />
    </div>
    </div>
    
   
    </>
  }
    </div>
  )
}

export default Homepage