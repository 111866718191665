import React, { useState, useEffect } from "react";
import Header from "../layout/Header";
import Sidebar from "../layout/Sidebar";
import PageTitle from "../components/PageTitle";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { DateRangePicker } from "rsuite";
import { format } from "date-fns";
import Loader from "../components/Loader";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import Select from "react-select";
import makeAnimated from "react-select/animated";
import { NumericFormat } from "react-number-format";
import Error from "../components/Error";
import Pagination from "../components/Pagination";
import { useLocation } from "react-router-dom";

const History = () => {

  const [filteredCreatedList, setFilteredCreatedList] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [sessionExpired, setsessionExpired] = useState(false);
  const [page, setPage] = useState(1);
  const [size, setSize] = useState(10);
  const [total, setTotal] = useState(1);
  const [count, setCount] = useState(1);

  useEffect(() => {

    getHistory(page, size);
  }, []);

  const animatedComponents = makeAnimated();

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };
  const token = localStorage.getItem("access");

  console.log(historyData, "ggggg");
  // Function to filter historyData based on searchQuery

  const getHistory = async (page, size) => {
    setLoading(true);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/api/v1/vendor/invoices?page=${page}&size=${size}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response.data, "I am the res");
      if (response.data.data && response.data.data.length === 0) {
        toast.error("No record found");
        setHistoryData([]); // Clear previous data if needed
      } else {
        console.log(response, "I am the res");
    setHistoryData(response.data.data.results);
    setCount(response.data.data.count)
    const totalPages=Math.ceil(response.data.data.count/size)
    setTotal(totalPages)
      }
      // handleNextPage()
    } catch (error) {
      console.error(
        "Error fetching search results:"
        // error.resonse
      );
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };
 
  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const pageParam = params.get("page");
    const pageSizeParam = params.get("page_size");

    const newPage = pageParam ? Number(pageParam) : 1;
    const newPageSize = pageSizeParam ? Number(pageSizeParam) : 10;

    setPage(newPage);
    setSize(newPageSize);

    getHistory(newPage, newPageSize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);
  const [statusFilter, setStatusFilter] = useState("All");
  const colourStyles = {
    control: (styles, state) => ({
      ...styles,
      // width: "140px",
      width: "140px",
      borderColor: "#eaeaea",
      margiTop: "-1px",
      Position:'relative',
      backgroundColor: state.isDisabled ? "grey" : "white",
      
    }),
  };
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  
  const filterTableData = (query, status, startDate, endDate) => {
    const formattedStartDate = startDate ? new Date(startDate).setHours(0, 0, 0, 0) : null;
    const formattedEndDate = endDate ? new Date(endDate).setHours(23, 59, 59, 999) : null;
  
    return historyData.filter((item) => {
      const { external_document_id, grn, gross_total, due_date } = item;
  
      // Search filter
      const matchesQuery = query
        ? [
            external_document_id?.toString().toLowerCase(),
            grn?.purchase_order?.po_id?.toString().toLowerCase(),
            grn?.grn_number?.toString().toLowerCase(),
            gross_total?.toString().toLowerCase(),
          ].some((field) => field?.includes(query.toLowerCase()))
        : true;
  
      // Date range filter
      const matchesDate = startDate && endDate
        ? new Date(due_date).getTime() >= formattedStartDate &&
          new Date(due_date).getTime() <= formattedEndDate
        : true;
  
      // Status filter
      const matchesStatus = status === "All"
        ? true
        : status === "Approved"
        ? item.workflow.completed && item.workflow.approved
        : status === "Declined"
        ? item.workflow.completed && !item.workflow.approved
        : status === "Pending"
        ? !item.workflow.completed
        : status === "Completely Delivered"
        ? item.grn.purchase_order.delivery_completed
        : status === "Partially Delivered"
        ? !item.grn.purchase_order.delivery_completed
        : true;
  
      return matchesQuery && matchesDate && matchesStatus;
    });
  };
  
  // Call filterTableData whenever a filter changes
  useEffect(() => {
    const filtered = filterTableData(searchQuery, statusFilter, startDate, endDate);
    setFilteredCreatedList(filtered);
  }, [searchQuery, statusFilter, startDate, endDate, historyData]);
  

console.log(filteredCreatedList,'new')
  const handleShowInvoice = (data) => {
    localStorage.setItem("PostResponse", JSON.stringify(data));
    navigate("/invoice", { state: { history: true } });
  };
  
  return (
    <>
      {!sessionExpired ? (
        <div className="">
          <Header />
          <div className="flex main-body">
            <div className="sidebar hidden md:block">
              <Sidebar />
            </div>
            <div className="content md:w-[80%] w-full md:ml-[20%] lg:w-[86%] lg:ml-[14%] p-3">
              <PageTitle pagetitle="Invoice History" className="mt-3" />
              {loading && <Loader />}
              {error && <Error message={error} />}
              <section class="bg-gray-50  sm:p-5">
                <div class="mx-auto w-full ">
                  <div class="bg-white flex flex-col relative   sm:rounded-lg overflow-hidden h-screen">
                    <div class="flex flex-col md:flex-row items-center justify-between space-y-3 md:space-y-0 md:space-x-4 p-2">
                      <div class="w-full md:w-1/3 lg:w-[40%]">
                        <form class="flex items-center">
                          <label for="simple-search" class="sr-only">
                            Search
                          </label>
                          <div class="relative w-full">
                            <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                              <svg
                                aria-hidden="true"
                                class="w-5 h-5 text-gray-500"
                                fill="currentColor"
                                viewBox="0 0 20 20"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  fill-rule="evenodd"
                                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                  clip-rule="evenodd"
                                />
                              </svg>
                            </div>
                            <input
                              type="text"
                              id="simple-search"
                              class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:outline-none block w-full pl-8 py-2"
                              placeholder="Search by PO number, reference number and total..."
                              value={searchQuery}
                              onChange={handleSearchChange}
                              required=""
                            />
                          </div>
                        </form>
                      </div>
                      <div className="flex gap-3 ">
                      <DateRangePicker
  format="dd-MM-yyyy"
  placeholder="Filter by Date Range"
  className="w-1/2 md:w-48"
  style={{ height: 50 }}
  ranges={[]}
  placement="bottomEnd"
  preventOverflow
  onChange={(range) => {
    const [startDate, endDate] = range || [];
    setStartDate(startDate || null);
    setEndDate(endDate || null);
  }}
  onClean={() => {
    setStartDate(null);
    setEndDate(null);
  }}
/>

<Select
  closeMenuOnSelect={true}
  components={animatedComponents}
  placement='bottomStart'
  className="w-1/2 md:w-48"
  styles={{
    ...colourStyles, // Keep your existing styles
    control: (provided) => ({
      ...provided,
      height: '36px',      // Set the height to 40px
      minHeight: '36px',   // Ensure the minimum height is also 30px
      padding: '0', 
       // Adjust padding for height control
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: '0 8px',    // Adjust padding inside the control
    }),
    input: (provided) => ({
      ...provided,
      margin: '0',         // Remove margin to fit the height
      padding: '0', 
             // Remove padding for input text
    }),
    menu: (provided) => ({
      ...provided,
      maxHeight: '400px',  // Limit the height for overflow
      overflowY: 'auto', 
      top:'33px', 
      zIndex:30 // Enable scrolling
    })
  }}
  placeholder='Filter by status'
  options={[
    { value: 'All', label: 'All' },
    { value: 'Pending', label: 'Pending' },
    { value: 'Approved', label: 'Approved' },
    { value: 'Declined', label: 'Declined' },
    { value: 'Partially Delivered', label: 'Partially Delivered' },
    { value: 'Completely Delivered', label: 'Completely Delivered' }
  ]}
  value={statusFilter ? { value: statusFilter, label: statusFilter } : null} // Show the selected value or nothing
  onChange={(selectedOption) => {
    setStatusFilter(selectedOption ? selectedOption.value : null); // Clear value if no selection
    const updatedFilteredList = filterTableData(selectedOption ? selectedOption.value : 'All'); // Call the combined filter function
    setFilteredCreatedList(updatedFilteredList); // Update state with the filtered data
  }}
/>




                      </div>
                    </div>

                    <div class="overflow-x-auto md:w-full w-[90%] mx-auto">
                      <table className="min-w-[1100px] w-full">
                        <thead className="rounded-lg text-left bg-blue-800 text-sm font-normal">
                          <tr className="font-poppins text-19 font-semibold leading-29">
                            <th scope="col" class="px-3 py-3 text-white">
                              SN
                            </th>

                            <th scope="col" class="px-3 py-3 text-white">
                              PO No
                            </th>
                            <th scope="col" class="px-3 py-3 text-white">
                              GRN No
                            </th>
                            <th scope="col" class="px-3 py-3 text-white">
                              Reference No
                            </th>
                           
                            <th scope="col" class="px-3 py-3 text-white">
                              Date Created
                            </th>
                           
                           
                            <th scope="col" class="px-3 py-3 text-white">
                              Delivery Status
                            </th>
                            <th
                              scope="col"
                              class=" py-3 text-right
                                pr-16 text-white"
                            >
                              Amount
                            </th>
                           
                            <th scope="col" class="px-3 py-3 text-white">
                              Approval Status
                            </th>

                            {/* <th scope="col" class="px-3 py-3 text-white">
                                  Delivery Status
                                </th> */}
                            <th
                              scope="col"
                              className=" py-3 pr-3 text-white text-center"
                            >
                              Action
                            </th>
                          </tr>
                        </thead>

                        <tbody>
  {filteredCreatedList && Array.isArray(filteredCreatedList) ? (
    filteredCreatedList.length === 0 ? (
      <tr>
        <td colSpan="8" className="text-center py-4 text-red-600 font-semibold">
          No record found!
        </td>
      </tr>
    ) : (
      filteredCreatedList.map((row, index) => (
        <tr className="w-full border-b py-2 text-sm" key={index}>
          <td className="px-3 py-3">
            {page === 1 ? page + index : size * page - size + 1 + index}
          </td>
          <td className="px-3 py-3">
            {row.grn?.purchase_order?.po_id ? row?.grn?.purchase_order?.po_id : "-"}
          </td>
          <td className="px-3 py-3">
            {row.grn?.grn_number ? row?.grn?.grn_number : "-"}
          </td>
          <td className="px-3 py-3">
            {row?.external_document_id ? row?.external_document_id : "-"}
          </td>
          <td className="px-3 py-3">
            {format(row?.date_created, "dd-MM-yyyy")}
            {/* {row.created} */}
          </td>
          <td className="px-3 py-3">
            <span className={`rounded-3xl px-3 py-1 ${
              row?.grn.purchase_order.delivery_status_text === 'Completely Delivered'
                ? 'bg-green-100 text-green-700'
                : row.purchase_order.delivery_status_text === 'Not Delivered'
                ? 'bg-red-100 text-red-600'
                : 'bg-gray-100 text-gray-600'
            }`}>
              {row.grn.purchase_order.delivery_status_text === 'Partially Delivered' ? 'Partial' : 'Complete'}
            </span>
          </td>
          <td className="text-right pr-16 py-3">
            ₦
            <NumericFormat
              value={row.gross_total}
              displayType="text"
              thousandSeparator={true}
              decimalSeparator="."
              decimalScale={2}
              fixedDecimalScale
            />
          </td>
         
          <td className="px-3 py-3">
          <span
            className={`rounded-3xl px-3 py-1 ${
             row.workflow.completed
                ?row.workflow.approved
                  ? 'bg-green-100 text-green-700'
                  : 'bg-red-100 text-red-700'
                : 'bg-gray-100 text-gray-600'
            }`}
          >
            {row.workflow.completed
              ?row.workflow.approved
                ? 'Approved'
                : 'Declined'
              : 'Pending'}
          </span>
            {/* {row.created} */}
          </td>
          <td className="flex py-3  font-semibold text-center">
            <button
              onClick={() => handleShowInvoice(row)}
              className="border border-blue-700 bg-blue-700 items-center flex gap-1 rows-center justify-center py-1 hover:bg-blue-800 text-white px-2 rounded"
            >
              <RemoveRedEyeIcon sx={{ fontSize: 16 }} />
              <span className="text-xs">View</span>
            </button>
          </td>
        </tr>
      ))
    )
  ) : (
    <tr>
      <td colSpan="8" className="text-center py-4 text-red-600 font-semibold">
        No record found!
      </td>
    </tr>
  )}
</tbody>


                      </table>
                      <Pagination
totalPages=
{total}
entriesPerPage=
{size}
totalEntries=
{count}/>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      ) : (
        <sessionExpired />
      )}
    </>
  );
};

export default History;