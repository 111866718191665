import React, { useState, useEffect } from "react";
import { Form, Modal, Spinner } from "react-bootstrap";
import AuthenticationPages from "../components/AuthenticationPages";
import OtpInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";
import { USER_LOGIN_FAIL, USER_LOGIN_SUCCESS, USER_LOGIN_REQUEST } from '../redux/constants/userConstants';

const TwoFactorPage = ({ username, password }) => {
  const [otp, setOtp] = useState(""); // To hold the OTP value
  const [codeIsValid, setCodeIsValid] = useState(null); // To check if the code is valid
  const [resend, setResend] = useState(false); // To check if resend is triggered
  const [timeLeft, setTimeLeft] = useState(120); // Timer for countdown (in seconds)
  const [resendAttempts, setResendAttempts] = useState(0); // Track the number of resend attempts
  const [showModal, setShowModal] = useState(false); // Modal state for success
  const [loading, setLoading] = useState(false); // Modal state for loader
  const [unauthorized, setUnauthorized] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Function to handle login (or OTP resend)
  const login = async () => {
    try {
      setCodeIsValid(null);
      dispatch({ type: USER_LOGIN_REQUEST });

      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/v1/vendor/authenticate`,
        { username, password }
      );

      if (!response.data) {
        throw new Error("Response data is undefined");
      }
      setResend(true);
      const { data } = response;
      
      localStorage.setItem("success", data.status);
      localStorage.setItem("username", username);

      dispatch({
        type: USER_LOGIN_SUCCESS,
        payload: data,
      });
      setLoading(false);
      setResend(false); // Indicate that the resend was triggered
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setUnauthorized(true); // Set unauthorized to true if 401 error occurs
      }

      dispatch({
        type: USER_LOGIN_FAIL,
        payload:
          error.response && error.response.data.detail
            ? error.response.data.detail
            : error.message,
      });
      setLoading(false);
      setResend(false);
    }
  };

  // Timer countdown logic
  useEffect(() => {
    const timer = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft((prev) => prev - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [timeLeft]);

  // Verify OTP whenever it changes and reaches length 6
  useEffect(() => {
    if (otp.length === 6) {
      verifyOtp();
    }
  }, [otp]);

  // Function to resend OTP
  const handleResendOtp = () => {
    if (resendAttempts < 3) {
      setTimeLeft(120); // Reset the timer
      setResendAttempts((prevAttempts) => prevAttempts + 1); // Increment resend attempts
      login(); // Dispatch login function to trigger resend OTP
    } else {
      setUnauthorized(true); // Set unauthorized to true after 3 resend attempts
    }
  };

  // Format the time left in minutes and seconds
  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return ` ${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  // Function to verify OTP
  const verifyOtp = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}api/v1/vendor/verify-otp`,
        {
          username,
          otp,
        }
      );

      if (!response.data) {
        throw new Error("Response data is undefined");
      }

      const { data } = response;
      localStorage.setItem("email", data.data.user.email);
      localStorage.setItem("access", data.data.access);
      localStorage.setItem("userInfo", JSON.stringify(data.data.user));
      setLoading(false);
      setCodeIsValid(true);
      setShowModal(true); // Show success modal

    } catch (error) {
      setLoading(false);
      console.log(error);
      setCodeIsValid(false);
      setOtp("");
    }
  };

  // Function to close modal and navigate to homepage
  const handleCloseModal = () => {
    setShowModal(false);
    navigate("/"); // Navigate to homepage after modal close
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <AuthenticationPages FormTitle="OTP Page">
        <Form.Group>
          <p className="my-0 mx-auto">
            A six-digit authentication code has been sent to your device. Please enter the code below:
          </p>
          <Form.Label className="mt-4 text-sm font-bold">OTP Code</Form.Label>

          <OtpInput
            numInputs={6}
            renderSeparator={<span>&nbsp;&nbsp;</span>}
            renderInput={(props) => <input {...props} />}
            shouldAutoFocus={true}
            value={otp}
            onChange={(value) => {
              setOtp(value);
              if (codeIsValid !== null) setCodeIsValid(null); // Clear error message when user starts typing
            }}
            containerStyle={{
              display: 'flex',
              justifyContent: 'space-between',
              width: '100%',
              margin: '0 auto',
            }}
            inputStyle={{
              width: '100%',
              maxWidth: '40px',
              height: '40px',
              fontSize: '20px',
              borderRadius: '4px',
              border: '1px solid #ccc',
              textAlign: 'center',
              backgroundColor: '#f3f4f6',
            }}
            focusStyle={{
              border: '1px solid #007bff',
              boxShadow: '0 0 5px rgba(0, 123, 255, 0.5)',
            }}
          />

          {codeIsValid === false && (
            <p style={{ color: 'red', width: '360px', margin: '4px auto 0 auto' }}>
              Invalid OTP code. Please try again.
            </p>
          )}

          {!unauthorized && (
            <div className="flex items-center justify-center mt-3">
              <p>
                <span
                  className={`${
                    timeLeft === 0 ? 'text-blue-600 cursor-pointer' : 'text-gray-600'
                  }`}
                  onClick={timeLeft === 0 ? handleResendOtp : ''}
                >
                  RESEND 
                </span>
                <span>{timeLeft >1 && ` in ${formatTime(timeLeft)}`}</span>
              </p>
            </div>
          )}

          {resend && (
            <div className="mt-3 text-center flex items-center justify-center text-gray-700">
              A six-digit code has been sent to your device. Kindly check
            </div>
          )}

          {unauthorized && (
            <div className="mt-6 bg-red-50 px-3 py-2 rounded flex items-center gap-3">
              <div>Maximum attempts reached!</div>
              <div
                className="text-center flex items-center justify-center text-gray-700 hover:underline cursor-pointer hover:text-blue-500"
                onClick={() => {
                  localStorage.removeItem('success');
                  navigate('/sign-in');
                  localStorage.setItem('reload', 'reload');
                }}
              >
                Click to login again
              </div>
            </div>
          )}
        </Form.Group>
      </AuthenticationPages>

      {/* Modal for Loader */}
      <Modal show={loading} centered>
        <Modal.Body className="text-center">
          <Spinner animation="border" variant="primary" />
          <p className="mt-2 text-sm text-gray-700">Verifying OTP...</p>
        </Modal.Body>
      </Modal>

      {/* Success Modal */}
      <Modal show={showModal} onHide={handleCloseModal} centered>
        <div className="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
          <div className="w-12 h-12 rounded-full bg-green-100 dark:bg-green-900 p-2  mx-auto mb-3.5">
            <svg
              aria-hidden="true"
              className="w-8 h-8 text-green-500 dark:text-green-400"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M16.707 5.293a1 1 0 00-1.414 0L9 11.586 5.707 8.293a1 1 0 10-1.414 1.414l4 4a1 1 0 001.414 0l7-7a1 1 0 000-1.414z"
                clipRule="evenodd"
              ></path>
            </svg>
          </div>
          <p className="mb-4 text-lg font-semibold text-gray-900 dark:text-white">Verification Successful!</p>
          <p className="text-sm font-normal text-gray-500 dark:text-gray-400">
          OTP verified successfully!
          </p>
          <div className="flex justify-center items-center space-x-4">
            <button
              onClick={handleCloseModal}
              type="button"
              className="py-2 px-3 mt-3 text-sm font-medium text-center text-white rounded-lg bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
            >
              Continue
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default TwoFactorPage;
