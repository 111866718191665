import React, { useState } from 'react';
import { logout } from '../redux/actions/userActions';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useDispatch} from 'react-redux';
import Logo from '../assets/images/FoodConcepts_Logo-1 1.png';
import { ReactComponent as Vector } from '../assets/images/Vector.svg';
import { ReactComponent as Vector1 } from '../assets/images/Vector (1).svg';
import AccountMenu from '../components/Dropdown';
import {ReactComponent as Settings} from '../assets/images/material-symbols_settings.svg'
import Avatar from '@mui/material/Avatar';


const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [showSidebar, setShowSidebar] = useState(false);
  const [expand, setExpand] = useState(false);
  const dispatch = useDispatch();
  const userLogin = JSON.parse(localStorage.getItem('userInfo'));
  const isLoggedIn = userLogin;
  const userName = userLogin?.vendor_name || '';

  const logoutHandler = () => {
    dispatch(logout());
    navigate('/sign-in');
  };

  return (
    <>
      {showSidebar ?  (
   <div className="fixed inset-0 z-30 flex w-full h-screen">
   <div className="text-base font-medium sidebar bg-white h-full w-full flex flex-col">
     <div className="flex justify-between">
       <img src={Logo} className="h-16 mx-1" alt="Logo" />
       <button
         className="text-xl p-2"
         onClick={() => setShowSidebar(false)}
       >
         &times;
       </button>
     </div>
 
     {/* Sidebar content with flex-grow to push logout to the bottom */}
     <ul className="text-base ">
      <li className={`sidebar-text  mt-3 py-2  ${location.pathname === '/' ? 'active' : location.pathname === '/invoice' ? 'active' : ''}`}>
  <Link className='flex gap-1 items-center' to='/'>
    <Vector className={`mx-2 icon   ${location.pathname === '/' ? 'active' : location.pathname === '/invoice' ? 'active' : ''}`} />
    <span>Create Invoice</span>
  </Link>
</li>

    <li className={`sidebar-text  mt-3 py-2 ${location.pathname === '/history' ? 'active':''}`}>
<Link className='flex gap-1 items-center' to='/history'>
  <Vector1 className={`mx-2 icon ${location.pathname === '/history' ? 'active'  : ''}`} />
  <span>Invoice History</span>
</Link>
</li>
    <li className={`sidebar-text  mt-3 py-2 ${location.pathname === '/profile' ? 'active'  : ''}`}>
<Link className='flex gap-1 items-center' to='/profile'>
  <Settings className={`mx-2 icon ${location.pathname === '/profile' ? 'active' : ''}`} />
  <span>Profile Settings</span>
</Link>
</li>

 
  </ul>
   </div>
 
   <div
     className="fixed inset-0 bg-black opacity-50"
     onClick={() => setShowSidebar(false)}
   ></div>
 </div>
 
      ) : (
        <div className="md:w-[80%] w-full md:ml-[20%] lg:w-[86%] lg:ml-[14%] flex header flex-col md:flex-row justify-center md:justify-between px-3 bg-white md:h-[70px] md:items-center py-10 md:py-0 ">
      <div className='flex items-center py-1 md:py-0'>
      <button
                data-collapse-toggle="navbar-multi-level"
                type="button"
                className="inline-flex items-center  justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 "
                aria-controls="navbar-multi-level"
                aria-expanded="false"
                onClick={() => setShowSidebar(!showSidebar)}
              >
                <span className="sr-only">Open main menu</span>
                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                  <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
                </svg>
              </button>
              <div>
              <div className="font-semibold text-lg  text-blue-900 ml-2 md:ml-0">Invoice Management System</div>
              <div className="flex ml-2 md:hidden  items-center text-base font-normal">
            {isLoggedIn ? (
              <>
           
          
                <span className="user-details self-center text-blue-900 md:ml-2">{userName}</span>
                <div className="dropdown-svg self-center" onClick={() => setExpand(!expand)}>
                  <AccountMenu SignOut={logoutHandler} />
                </div>
              </>
            ) : (
              <Link to="/sign-in">Login</Link>
            )}
          </div>
              </div>
             
      </div>
          
              
          <div className=" hidden md:flex items-center text-base font-normal">
            {isLoggedIn ? (
              <>
              <div className="hidden lg:flex"> <Avatar sx={{ bgcolor: '#1e3a8a ',width: 24, height: 24 }}  /></div>
              
                {/* <Avatar alt="User Image" src={userImage || ''} sx={{ bgcolor: !userImage && '#1e3a8a' }} className='mr-4'/> */}
                <span className="user-details self-center text-blue-900 md:ml-2">{userName}</span>
                <div className="dropdown-svg self-center" onClick={() => setExpand(!expand)}>
                  <AccountMenu SignOut={logoutHandler} />
                </div>
              </>
            ) : (
              <Link to="/sign-in">Login</Link>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default Header;
