import React, { useState, useEffect } from "react";
import { ReactComponent as ArrowLeft } from "../assets/images/arrowLeft.svg";
import { NumericFormat } from "react-number-format";
import { useNavigate } from "react-router-dom";
import ApprovalStatusImage from "../assets/images/image 12.png";
import Box from "@mui/material/Box";
import InformationIcon from "../assets/images/infoicon.png";
import Modal from "@mui/material/Modal";
import axios from "axios";
import Error from "../components/Error";
import Loader from "../components/Loader";
import {format} from 'date-fns'
const InvoiceCreationStep5 = ({
	handlePrevious,
	currentPage,
	totalPages,
	vendorName,
}) => {
	const ReferenceNumber = localStorage.getItem("ReferenceNumber");
	const DueDate = localStorage.getItem("DueDate");
	console.log(DueDate,'due date')
	const [loading, setLoading] = useState(false);
	const [showErrow, setShowError] = useState(false);
	const [error, setError] = useState("");
	const pageData=JSON.parse(localStorage.getItem('vendorDetails')) || []
	const userInfo=JSON.parse(localStorage.getItem('userInfo')) || []
	const Colour = pageData?.vendor_settings?.invoice_color ?? '#027C94';
// const Colour = '#027C94';
	
	const [SelectedItems,setSelectedITEMS] = useState([])
	useEffect(()=>{
		setSelectedITEMS(JSON.parse(
			localStorage.getItem("selectedItems")
		) || { filteredData: [] })
	},[])	
const token = localStorage.getItem('access')
	const groupedItems = SelectedItems.reduce((acc, item) => {
		const { grn_number, id } = item;
		
		if (!acc[grn_number]) {
			acc[grn_number] = {
				grn_number: grn_number,
				vendor_document_id:parseFloat(ReferenceNumber),
				description:'',
				due_date:DueDate,
				payment_terms:'',
				payment_reason:'', // You mentioned description will be empty
				invoice_line_items: []
			};
		}
		
		acc[grn_number].invoice_line_items.push({ grn_line_item_id: id });
		
		return acc;
	}, {});
	
	const invoiceData = Object.values(groupedItems);
	
	const notempty = SelectedItems.some(value => value.discountStatus);
	

	const vendorname = pageData?.user?.vendor_name
	const handleGenerate = () => {
		// // Show the modal
		setShowModal(false);
		setShowGenerateModal(false)
		handleButtonClick();
		console.log(SelectedItems, "jiji moji");
	};
	function sumTaxValue() {
		return SelectedItems.reduce((total, record) => total + parseFloat(record.tax_value), 0);
	}
	const calculateTotalAmount = () => {
		// Use the reduce method to iterate over selectedItems
		const totalAmount = SelectedItems.reduce((total, item) => {
			// Extract quantity and unit price from each item
			const quantity = parseFloat(
				item.net_value_received

			);
			return total + quantity ;
		}, 0); // Start with an initial total of 0

		// Return the total amount
		return totalAmount;
	};

	// console.log(calculateTotalAmount);
	const handleButtonClick = async () => {
		try {
			setLoading(true);
			console.log(invoiceData,'invoiced')
			// Make sure to replace 'invoiceData' with your actual data
			const response = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/api/v1/vendor/invoices`,
				invoiceData,
				{
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`,
					},
				}
			);

			if (response.status === 201) {
				localStorage.setItem(
					"PostResponse",
					JSON.stringify(response.data.data)
				);
				// console.log(response.data);
				setShowModal(true);
			}
		} catch (error) {
			setShowGenerateModal(false);
			setShowError(!showErrow);
			// console.log(showErrow, "SHHH");
			console.error("Error:", error.response);
			// Handle errors as needed
			setError(
				error.response && error.response.data.message
					? error.response.data.message
					: error.message
			);
			localStorage.removeItem('SelectedItems')
			localStorage.removeItem('selectedItems')
			localStorage.removeItem('selectedItemswithduties')
			localStorage.removeItem('selectedRows')
		} finally {
			setLoading(false);
		}
	};
	const navigate = useNavigate();
	const [showModal, setShowModal] = useState(false);
	const [showGenerateModal, setShowGenerateModal] = useState(false);
	const SelectedItemswithoutduties = JSON.parse(
		localStorage.getItem("SelectedItems")
	) || { filteredData: [] };
	const [isChecked, setIsChecked] = useState(false);
	console.log(SelectedItems,'selecteditems')
	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		bgcolor: "background.paper",
		boxShadow: 24,
		p: 4,
	};
console.log(SelectedItemswithoutduties,'selecteditemswith duty')
  
	return (
		<div>
			{error && (
				<Error
					show={showErrow}
					message={error}
					handleShow={() => {
						setShowError(!showErrow);
					}}
				/>
			)}
			{loading && <Loader/>}
			<Modal open={showModal} onClose={() => setShowModal(false)}>
				<Box sx={style}>
					<div className="flex items-center flex-col justify-center">
						<img src={ApprovalStatusImage} alt="" />
						<div className="text-center ">Invoice Succesfully created, click the button below to view receipt!</div>
						<button
							className="my-2 bg-green-600 text-white py-2 px-4 rounded-md hover:bg-green-700"
							onClick={() => {
								navigate("/history");
							}}
						>
							Okay
						</button>
					</div>
				</Box>
			</Modal>
			<Modal
				open={showGenerateModal}
				onClose={() => setShowGenerateModal(false)}
				centered
			>
				<Box sx={style}>
					<div className="flex items-center flex-col justify-center">
						<img src={InformationIcon} alt="" />
						<div className="text-center py-2">
							Do you want to generate the Invoice?
						</div>
						<div className="flex gap-4">
							<button
								className="my-2 bg-blue-600 text-white py-2 px-2 w-24 rounded-md hover:bg-blue-700"
								onClick={handleGenerate}
							>
								Yes
							</button>
							<button
								className="my-2 bg-red-600 text-white py-2 px-2 w-24 rounded-md hover:bg-red-700"
								onClick={() => {
									setShowGenerateModal(false);
								}}
							>
								Cancel
							</button>
						</div>
					</div>
				</Box>
			</Modal>
			<div className="pb-5 box-shadow bg-white flex flex-col">
				<div className="flex p-3 justify-between bg-blue-800 text-base text-white">
					<div className="flex gap-2">
						<button
							className="border-0 bg-transparent"
							onClick={handlePrevious}
						>
							<ArrowLeft />
						</button>
						<div className="flex">Invoice Details</div>
					</div>
					<span className="border-gray-700">
						Step {currentPage} of {totalPages}
					</span>
				</div>
				<div className="p-4">
					<div className="flex justify-between">
					<div>
                  <img src={pageData?.vendor_settings?.logo} alt="vendor logo" className='w-32 h-32 my-3 object-contain' />

                    <p className='text-xl  py-1 font-bold'style={{color:Colour}}>{vendorname}</p>
                    <p className='text-base'>Telephone: {pageData?.phone}</p>
                    
                    <p className='text-base'>Email: {userInfo?.email}</p>
                    
                    <div className="w-full text-white text-base font-semibold bg-teal-600 pl-2 py-2 mt-3" style={{ backgroundColor: Colour }}>BILL TO</div>
                    <div className="text-[#545454] py-1 ">FOOD CONCEPT LTD </div>
                    <div className="text-[#545454] py-1 ">{SelectedItems?.grn?.stores[0]?.store_name||''}</div>
                    <div className="text-[#545454] py-1">LAGOS</div>
                  </div>
				  <div className=''>

<div className="text-right  font-bold text-xl mb-1"style={{color:Colour}}>INVOICE</div>
<div className="border-2  font-normal text-[#545454] flex flex-col" style={{borderColor:Colour}}>
  <div className="flex p-2 m-0">
	<span> Date</span>
	<span className="text-right flex-grow ">{format(new Date(),'dd-MMM-yyyy')}</span>
  </div>
 
  <div className="flex gap-36 m-0 p-2">
	<span>Purchase Order Number</span>
	<span className="flex-grow  text-right">
	{SelectedItemswithoutduties.poNumber}
	</span>
  </div>
  <div className="flex gap-36 m-0 p-2">
	<span>Reference Number</span>
	<span className="flex-grow  text-right">
	{ReferenceNumber}
	</span>
  </div>

  <div className="flex   gap-36 m-0 p-2">
	<span>Total</span>
	<span className="flex-grow  text-right"> 
	₦<NumericFormat
												value={calculateTotalAmount()+sumTaxValue()}
												displayType={"text"}
												thousandSeparator={true}
												decimalSeparator="."
												decimalScale={2}
												fixedDecimalScale
											/>
	</span>
  </div>
</div>
</div>
					</div>
				</div>
				<div className="py-4 ">
					<table className="w-full mt-4 ">
						<thead className=" text-white text-sm w-full"style={{backgroundColor:Colour}}>
							<tr>
								<td className="text-sm py-2 pl-2">SN</td>
								<td className="text-sm py-2 pl-2">GRN Number</td>
								
								<th className="text-sm py-2 pl-8">Product Description</th>
								
								<th className="text-sm py-2 ">Product Code</th>
								<th className="text-sm py-2 pl-4">UoM</th>
								<th className="text-sm py-2 text-right pr-4">Unit Price</th>
								<th className="text-sm py-2 text-right pr-4">Quantity</th>
								<th className="text-sm py-2 text-right pr-4">Tax Value</th>
								
								<th className="text-sm py-2 text-right pr-4">Total Amount</th>
								
								
								{notempty&&<th className="text-sm py-2">Discount</th>}
								
							</tr>
						</thead>
						<tbody>
							{SelectedItems.map((item, index) => (
								<tr key={`${index}`} className="text-sm leading-7 h-12 border-b dark:border-gray-700 ">
									<td className="text-sm pl-2">{index + 1}</td>
									<td className="text-sm pl-2">{item.grn_number}</td>
								 <td className="pl-8 text-sm">{item.purchase_order_line_item.product_name}</td>
        <td className=" text-sm">{item.purchase_order_line_item.metadata.ProductID}</td>
		<td className="pl-4 text-sm">
										{item.purchase_order_line_item.metadata.ListUnitPriceBaseUnitCode}
									</td>
									<td className="text-sm text-right pr-4">
									₦<NumericFormat
											value={item.purchase_order_line_item.unit_price}					
											displayType={"text"}
											thousandSeparator={true}
											decimalSeparator="."
											decimalScale={2}
											fixedDecimalScale
										/>
									</td>
									<td className="text-right pr-4 text-sm">
										<NumericFormat
											value={item.quantity_received}
											displayType={"text"}
											thousandSeparator={true}
											decimalSeparator="."
											decimalScale={2}
											fixedDecimalScale
										/>
									</td>
									<td className="text-right pr-4 text-sm">
									₦<NumericFormat
											value={item.tax_value}
											displayType={"text"}
											thousandSeparator={true}
											decimalSeparator="."
											decimalScale={2}
											fixedDecimalScale
										/>
									</td>
									
									<td className="text-right pr-4 text-sm pl-8">
									₦<NumericFormat
											value={item.net_value_received}
											displayType={"text"}
											thousandSeparator={true}
											decimalSeparator="."
											decimalScale={2}
											fixedDecimalScale
										/>
									</td>
									{notempty&&
										<td className="text-sm">
										{item.discountValue && item.discountType
											? item.discountType === "%"
												? item.discountValue + item.discountType
												: item.discountType + item.discountValue
											: 0}
									</td>
									}
								
									
								</tr>
							))}
						
						</tbody>
					</table>
				</div>
				<div className="flex w-full px-4">
                <div className=" w-full mt-8 text-[#545454]">
                </div>

                <div className="w-full mt-8 text-[#545454]">
                  <div className="text-right" style={{ color: Colour }}>
                    Subtotal: ₦
                    <NumericFormat
										   value={calculateTotalAmount()}
										   displayType={"text"}
										   thousandSeparator={true}
										   decimalSeparator="."
										   decimalScale={2}
										   fixedDecimalScale
									   />
                  </div>
                  <div className="text-right mt-2" style={{ color: Colour }}>
                    Tax: ₦
					<NumericFormat
										   value={sumTaxValue()}
										   displayType={"text"}
										   thousandSeparator={true}
										   decimalSeparator="."
										   decimalScale={2}
										   fixedDecimalScale
									   />
                  </div>
                  <div className="text-right mt-2 font-bold text-lg" style={{ color: Colour }}>
                    Total: ₦
                    <NumericFormat
												value={calculateTotalAmount()+sumTaxValue()}
												displayType={"text"}
												thousandSeparator={true}
												decimalSeparator="."
												decimalScale={2}
												fixedDecimalScale
											/>
                  </div>
                </div>
              </div>
				<div className="flex justify-center items-center px-40 mt-12 mb-4">

				<div>
<p className='text-center text-lg signature'></p>
<hr className='divider' />
<p className='text-center text-base my-2'>Vendor Signature</p>
</div>


              </div>
			  
				
				<div className=" mb-4 mx-4 inline-block">
					<input
						type="checkbox"
						id="myCheckbox"
						className="form-checkbox border-0 py-2 h-[0.9rem] w-[0.9rem] bg-blue-900"
						checked={isChecked}
						onChange={(e) => setIsChecked(e.target.checked)}
					/>
					<label
						htmlFor="myCheckbox"
						className="ml-2  text-[#545454] py-2 text-[15px]"
					>
						I confirm that I've reviewed and validated this Invoice. Once submitted,
						no changes can be made.
					</label>
				</div>
				{isChecked && (
					<button
						className="flex self-end mb-4 mx-4 bg-blue-700 rounded text-white py-2 px-6"
						onClick={() => {
							setShowGenerateModal(true);
						}}
					>
						Generate
					</button>
				)}
			</div>
		</div>
	);
};

export default InvoiceCreationStep5;