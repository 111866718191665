import React,{useState} from 'react';
import { PublicClientApplication } from "@azure/msal-browser";
import { Link } from 'react-router-dom';
import formatText from '../../components/formatText';
import Logo2 from '../../assets/images/FoodConcepts_Logo-1 1.png'
import {ReactComponent as Vector1} from '../../assets/images/Vector (7).svg'
import {ReactComponent as Vector} from '../../assets/images/arrowfandb.svg'
import ListItemIcon from '@mui/material/ListItemIcon';
import MenuItem from '@mui/material/MenuItem';
import Logout from '@mui/icons-material/Logout';
const Header = () => {
   
    const name = localStorage.getItem("name");
    const userName = localStorage.getItem("username");
    const msalConfig = JSON.parse(localStorage.getItem("msalConfig"));
    const roles = JSON.parse(localStorage.getItem("roles"));
    const [confirmLogout, setConfirmLogout] = React.useState(false);
    const [showSidebar, setShowSidebar] = useState(false);
    const handleLogout = () => {
      const msalInstance = new PublicClientApplication(msalConfig);
      msalInstance.initialize()
          .then(()=>{
              const logoutRequest = {
                  account: msalInstance.getAccountByUsername(userName),
                   mainWindowRedirectUri: 'https://vimp.foodconceptsplc.com/approval',
              }
  
              // localStorage.setItem('isLoggedin', false);
             
              msalInstance.logoutPopup(logoutRequest);
              setConfirmLogout(false)
              localStorage.clear()
          })
          .catch((error)=>{
              console.log(error)
          })
    };

    return (
      <>
          {confirmLogout && (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center w-screen h-screen z-50">
              <div className="p-4 sm:p-10 bg-gray-50 rounded-md w-[300px] md:w-[500px] text-center">
                <span className="mb-4 inline-flex justify-center items-center w-[62px] h-[62px] rounded-full border-4 border-yellow-50 bg-yellow-100 text-yellow-500">
                  <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
                    <path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 1 0 1 1 0 2 1 1 0 0 1 0-2z"></path>
                  </svg>
                </span>
    
                <h3 className="mb-2 text-2xl font-bold text-gray-800">Log out</h3>
                <p className="text-gray-500">Are you sure you would like to log out of your account?</p>
    
                <div className="mt-6 flex justify-center gap-x-4">
                  <button
                    className="py-2.5 px-4 inline-flex justify-center items-center gap-2 rounded-md border border-transparent font-semibold bg-blue-white cursor-pointer text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 transition-all text-sm"
                    onClick={() => setConfirmLogout(false)}
                  >
                    Cancel
                  </button>
                  <button
                    className="py-2.5 px-4 inline-flex justify-center items-center gap-2 rounded-md border font-medium bg-blue-500 shadow-sm hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-blue-600 transition-all text-sm text-white"
                    onClick={handleLogout}
                  >
                    Proceed
                  </button>
                </div>
              </div>
            </div>
          )}
      {showSidebar ? (
   <div className="fixed inset-0 flex w-full h-screen">
   <div className="text-base font-medium sidebar bg-white h-full w-full flex flex-col">
     <div className="flex justify-between">
       <img src={Logo2} className="h-16 mx-1" alt="Logo" />
       <button
         className="text-xl p-2"
         onClick={() => setShowSidebar(false)}
       >
         &times;
       </button>
     </div>
 
     {/* Sidebar content with flex-grow to push logout to the bottom */}
     <ul className="flex-grow">
       <Link
         to={'/approval/home'}
         className={`d-flex w-full items-center px-4 text-blue-800 hover:bg-[#A8C3F5] no-underline active:bg-[#A8C3F5] py-2 my-3 gap-3 `}
         style={{ textDecoration: 'none' }}
       >
         <Vector1 />
         <p>Home</p>
       </Link>
 
       <Link
         to={'/approval/all'}
         className={`link d-flex mb-3 items-center px-4 text-blue-800 hover:bg-[#A8C3F5] no-underline hover:text-blue-800 active:bg-[#A8C3F5] active:text-blue-800 py-2 gap-3 `}
         style={{ textDecoration: 'none' }}
       >
         <Vector />
         <span>Invoices</span>
       </Link>
     </ul>
 
     {/* Logout button at the bottom */}
     <div className="py-4">
       <MenuItem onClick={() => setConfirmLogout(true)} className="text-blue-800 px-3">
         <ListItemIcon>
           <Logout fontSize="small" className="text-blue-800" />
         </ListItemIcon>
         Logout
       </MenuItem>
     </div>
   </div>
 
   <div
     className="fixed inset-0 bg-black opacity-50"
     onClick={() => setShowSidebar(false)}
   ></div>
 </div>
 
      ) : (
        <div className='header flex md:justify-between px-3 md:py-2 md:w-[80%]  md:ml-[20%] lg:w-[86%] lg:ml-[14%] w-full items-center '>
      <span className='hidden md:flex text-base text-blue-800 font-semibold'>Invoice Approval Workflow</span>
    
          <div className="flex sm:hidden items-center text-blue-900 w-full gap-2">
            <button
              data-collapse-toggle="navbar-multi-level"
              type="button"
              className="inline-flex items-center mr-1 justify-center text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200"
              aria-controls="navbar-multi-level"
              aria-expanded="false"
              onClick={() => setShowSidebar(!showSidebar)}
            >
              <span className="sr-only">Open main menu</span>
              <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 1h15M1 7h15M1 13h15" />
              </svg>
            </button>
            <div>
         <div className='font-semibold text-lg '>Invoice Approval Workflow</div>
         <div className="items-center text-blue-900 g max-w-screen ">
  <span className="text-[0.9rem] border-r border-blue-600 pr-2">
    {name ? name : ''}
  </span>
  <span className="text-[0.8rem] md:-mt-1 ml-2">
  {roles && roles.length > 0 
    ? roles[1] 
      ? formatText(roles[1]) 
      : formatText(roles[0])
    : ''}
</span>

</div>

         </div>
          </div>
        
          <div className=" items-center hidden md:flex text-blue-900 text-base">
            <div className="flex-col  items-center border-r border-blue-600 pr-2">
              <div style={{ textAlign: 'right' }} className="text-[0.9rem] w-full"> {name ? name : ''}</div>
              <div style={{ textAlign: 'right' }} className="text-[0.8rem] md:-mt-1 w-full"> 
              {roles && roles.length > 0 
    ? roles[1] 
      ? formatText(roles[1]) 
      : formatText(roles[0])
    : ''}
              </div>
            </div>
            <div
              className="text-blue-900 pl-2 text-sm hidden md:flex self-center cursor-pointer"
              onClick={() => { setConfirmLogout(true) }}
            >
              Logout
            </div>
          </div>
        </div>
      )}
    </>
    
     
    );
}

export default Header;
