import React, { useState, useEffect, useRef } from 'react';
import Header from '../layout/Header';
import Sidebar from '../layout/Sidebar';
import html2canvas from 'html2canvas';
import { ReactComponent as DownloadIcon } from '../assets/images/download.svg';
import { ReactComponent as PrintIcon } from '../assets/images/print.svg';
import { useNavigate } from 'react-router-dom';
import { jsPDF } from 'jspdf';
import axios from 'axios';
import { Steps } from 'rsuite';
import { format } from 'date-fns';
import { ReactComponent as ArrowLeft } from '../assets/images/grey-arrow.svg';
import Loader from '../components/Loader';
import { NumericFormat } from 'react-number-format';
import formatText from '../components/formatText'
const steps = [
  { title: 'Pending', status: 'process' }, // Initially set to 'process'
  { title: 'Approved', status: 'wait' }, // Initially set to 'wait'
  { title: 'Declined', status: 'wait' }, // Initially set to 'wait'
];
const InvoicePage = () => {
  const [vendordetails, setVendorDetails] = useState([]);
  const userInfo = JSON.parse(localStorage.getItem('userInfo')) || [];
  const navigate = useNavigate();
  const imageRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const name = localStorage.getItem("name");
  const SelectedItems = JSON.parse(localStorage.getItem('PostResponse')) || { filteredData: [] };
  console.log(SelectedItems,'selecitems')
  const taxincluded = SelectedItems?.invoice_line_items?.some(item => item.tax_amount);
  const discountincluded = SelectedItems?.invoice_line_items?.some(item => item.discounted);

  useEffect(() => {
    if (!SelectedItems) {
      navigate('/');
    } else {
      const fetchData = async () => {
        setLoading(true);
        try {
          const token = localStorage.getItem('access'); // Assuming token is stored in localStorage
          const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/v1/vendor/profile`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );
          setVendorDetails(response.data.data);
          localStorage.setItem(
            'vendorDetails',
            JSON.stringify(response.data.data)
          );
        } catch (error) {
          console.error('Error:', error.message);
        } finally {
          setLoading(false);
        }
      };

      fetchData();
    }
  }, []);
  const { completed, approved, signatures } = SelectedItems.workflow;

  let currentStep = 0; // Default to "Pending Approval"

if (SelectedItems.workflow.completed) {
  if (SelectedItems.workflow.approved) {
    steps[0].status = 'finish'; // "Pending Approval" is checked
    steps[1].status = 'finish'; // "Approved" is checked
    steps[2].status = 'wait'; // "Approved" is checked
    currentStep = 1; // Set current step to "Approved"
  } else {
    steps[0].status = 'finish'; // "Pending Approval" is checked
    steps[1].status = 'error';  // "Approved" is marked as error
    steps[2].status = 'finish';  // "Approved" is marked as error
    currentStep = 2; // Set current step to "Declined"
  }
} else {
  steps[0].status = 'process'; // Current step is "Pending Approval"
  currentStep = 0; // Set current step to "Pending Approval"
  steps[1].status = 'wait';
  steps[2].status = 'wait';
}
  function createPDF() {
    const imgElement = document.querySelector('img');
    const imgSrc = imgElement.src;
  
    const fetchBase64Image = (url) => {
      return fetch(url)
        .then(response => {
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          return response.blob();
        })
        .then(blob => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
          });
        });
    };
  
    fetchBase64Image(imgSrc)
      .then(base64Img => {
        console.log('Image fetched and converted to Base64');
        return html2canvas(document.querySelector('#pdf'), {
          useCORS: true,
          onclone: (clonedDoc) => {
            let images = clonedDoc.querySelectorAll('img');
            images.forEach(img => {
              img.crossOrigin = 'anonymous';
              if (img.src === imgSrc) {
                img.src = base64Img;
              }
            });
          }
        });
      })
      .then(canvas => {
        console.log('Canvas created');
        let img = new Image();
        img.src = canvas.toDataURL('image/png');
        img.onload = function () {
          let pdf = new jsPDF('landscape', 'mm', 'a4');
          pdf.addImage(img, 'PNG', 0, 0, pdf.internal.pageSize.width, pdf.internal.pageSize.height);
          pdf.save(`Invoice_${SelectedItems?.grn.purchase_order.po_id}.pdf`);
        };
      })
      .catch(error => {
        console.error('Error creating PDF:', error);
      });
  }
  
  const print = () => {
    navigate('/pdf');
  };
  const styles = {
  display: 'inline-table',
    verticalAlign: 'center'
  };
  
  const vendorname = vendordetails?.user?.vendor_name;
  const Signature = vendordetails.signature ? vendordetails.signature : 'Vendor name';
  const Colour = vendordetails?.vendor_settings?.invoice_color ?? '#027C94';
  console.log(vendordetails,'vendor details')
 
    // Determine the status, comment, and color
    let status = '';
    let comment = '';
    let statusColor = '';
  
    if (completed && approved) {
      status = 'Approved';
      // comment = signatures.find(sig => sig.role === 'line_manager')?.comment || '';
      statusColor = 'text-green-600'; // Green for Approved
    } else if (completed && !approved) {
      status = 'Declined';
      // comment = signatures.find(sig => sig.role === 'internal_control')?.comment || '';
      statusColor = 'text-red-600'; // Red for Declined
    } else {
      status = 'Pending';
      statusColor = 'text-yellow-600'; // Yellow for Pending
    }
  return (
    <div>
      <Header />
      <div className="flex main-body">
        <div className="sidebar hidden md:block">
          <Sidebar />
        </div>
        <div className="content p-3 md:w-[80%] w-full md:ml-[20%] lg:w-[86%] lg:ml-[14%] ">
        <div className="hidden md:flex mx-auto w-full bg-white ">
          <Steps current={currentStep} className="w-[80%] mx-auto overflow-x-auto my-3">
  {steps.map((step, index) => (
    <Steps.Item key={index} title={step.title} status={step.status} />
  ))}
</Steps>

          </div>

<div className="flex md:hidden flex-col mx-auto w-full bg-white px-4 items-center">
  <div className="w-full sm:w-[80%] mx-auto my-3">
    <Steps vertical style={styles} current={currentStep} className="w-full">
      {steps.map((step, index) => (
        <Steps.Item key={index} title={step.title} status={step.status} />
      ))}
    </Steps>
  </div>
</div>


          <div className="flex justify-between my-3 text-sm text-[#545454] bg-white py-3 px-3 md:px-4">
            <button
              className="flex items-center justify-center gap-2"
              onClick={() => navigate('/history')}
            >
              <ArrowLeft /> History
            </button>
            <div className="flex gap-3 md:gap-4">
              <button
                className="flex items-center gap-2 text-sm font-normal"
                onClick={print}
              >
                <PrintIcon />
                <span>Print</span>
              </button>
              <button
                className="flex items-center gap-2 text-sm font-normal"
                onClick={createPDF}
              >
                <DownloadIcon />
                <span>Download</span>
              </button>
            </div>
          </div>
          {loading && <Loader />}
          <div className="bg-white flex flex-col py-4  w-full" id="pdf">
            <div className='w-full overflow-x-auto'>
              <div className="flex flex-col md:flex-row  md:justify-between px-4">
                <div>
                  <img
                    ref={imageRef}
                    src={userInfo?.vendor_settings?.logo}
                    alt="vendor logo"
                    className="w-32 h-32 my-3 object-contain rounded-full"
                  />
                  <p
                    className="text-xl py-1 font-bold"
                    style={{ color: Colour }}
                  >
                    {/* {userInfo?.vendor_settings?.logo} */}
                    {vendorname}
                  </p>
                <p className="text-base flex w-full"><div className='w-[24%] font-medium'>Telephone</div>  <div className="w-[76%]">{vendordetails?.phone}</div></p>
                <p className="text-base flex w-full"><div className='w-[24%] font-medium'>Email</div>  <div className="w-[76%]">{userInfo?.email}</div></p>
           
                  <div
                    className="w-full md:w-[26rem] text-white text-base font-semibold bg-teal-600 pl-2 py-2 mt-3"
                    style={{ backgroundColor: Colour }}
                  >
                    BILL TO
                  </div>
                  {/* <div className="text-[#545454] mt-2 py-1 flex w-3/5">
                    <div className='w-2/5 font-medium'>Store Name </div>
                    <div className='w-3/5'>{SelectedItems.grn.store.store_name||''}</div>
                  </div> */}
                  <div className="text-[#545454] flex  py-1 w-3/5">
                    <div className='w-2/5 font-medium'>Address </div>
                    <div className='w-3/5'>{SelectedItems?.grn?.stores[0]?.store_name||''}</div>
                  
                  </div>
                  {/* <div className="text-[#545454] py-1">LAGOS</div> */}
                </div>
                <div>
                  <div
                    className="text-right font-bold text-xl mb-1"
                    style={{ color: Colour }}
                  >
                    INVOICE
                  </div>
                  <div
                    className="border-2 font-normal text-[#545454] flex flex-col"
                    style={{ borderColor: Colour }}
                  >
                    <div className="flex p-2 m-0">
                      <span> Date</span>
                      <span className="text-right flex-grow ">
                        {format(new Date(SelectedItems?.due_date), 'dd-MMM-yyyy')}
                      </span>
                    </div>
                    <div className="flex gap-36 m-0 p-2">
                      <span>Reference Number</span>
                      <span className="flex-grow text-right">
                        {SelectedItems?.external_document_id}
                      </span>
                    </div>
                    <div className="flex gap-36 m-0 p-2">
                      <span>Purchase Order Number</span>
                      <span className="flex-grow text-right">
                        {SelectedItems?.grn?.purchase_order?.po_id}
                      </span>
                    </div>
                    <div className="flex gap-36 m-0 p-2">
                      <span>GRN</span>
                      <span className="flex-grow text-right">
                        {/* ₦
                        <NumericFormat
                          value={SelectedItems.gross_total}
                          displayType="text"
                          thousandSeparator={true}
                          decimalSeparator="."
                          decimalScale={2}
                          fixedDecimalScale
                        /> */}
                        {SelectedItems.grn.grn_number}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
<div className="bg-white overflow-x-auto md:w-full w-full px-2 mx-auto">
    <table className="md:w-full w-[1024px] mt-4 border-gray-200 ">
                <thead
                  className=" text-white text-sm w-full"
                  style={{ backgroundColor: Colour }}
                >
                  <tr>
                    <td className="text-sm font-semibold py-2 pl-2">SN</td>
                    {/* <td className="text-sm font-semibold py-2 pl-2">GRN</td> */}
                    <td className="text-sm font-semibold pl-8">Description</td>
                    <td className="text-sm font-semibold pl-4">UoM</td>
                    <td className="text-sm font-semibold text-right pr-4 py-2">
                      Unit Price
                    </td>
                    <td className="text-sm font-semibold text-right pr-4">
                      Quantity
                    </td>
                    {taxincluded && <td className="text-sm font-semibold text-right pr-4">
                      Tax 
                    </td>}
                    <td className="text-sm font-semibold text-right pr-4">
                      Discount
                    </td>
                   
                    <td className="text-sm font-semibold text-right pr-4">
                      Total
                    </td>
                    {discountincluded && (
                      <td className="text-sm font-semibold pl-4">Discount</td>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {SelectedItems.invoice_line_items.map((item, index) => (
                    <tr key={index} className="border-b dark:border-gray-700 text-sm">
                      <td className="text-sm pl-2 py-3">{index + 1}</td>
                      {/* <td className="text-sm pl-2 py-3">{SelectedItems.grn.grn_number}</td> */}
                      <td className="pl-8 text-sm">{item?.grn_line_item?.purchase_order_line_item?.product_name}</td>
                      <td className="pl-4 text-sm">{item?.grn_line_item?.purchase_order_line_item?.metadata?.ListUnitPriceBaseUnitCode
                      }</td>
                      <td className="text-sm text-right pr-4">
                        ₦<NumericFormat value={item?.grn_line_item?.purchase_order_line_item?.unit_price} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
                      </td>
                      <td className="pl-4 text-sm text-right pr-4">
                        <NumericFormat value={item?.quantity ? item?.quantity : item?.quantity_received} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
                      </td>
                      {taxincluded && 
                        <td className="text-sm text-right pr-4 pl-8">
                        ₦<NumericFormat value={item?.grn_line_item?.tax_value} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
                        </td>} 
                        <td className="text-sm text-right pr-4 pl-8">
                        ₦<NumericFormat value={item?.discount_amount?item?.discount_amount:0} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
                        </td>
                      {discountincluded && <td className="text-sm pl-4">{item.discount ? item.discount : 0.00}</td>}
                      <td className="text-sm text-right pr-4">
                        ₦<NumericFormat value={item?.net_total} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
</div>
            
<div className="flex w-full px-4 items-center">
      {/* <div className="w-full mt-8 text-[#545454]">
        <div className="font-bold" style={{ color: Colour }}>
          Note
        </div>
        <p>
          If you have any questions about this invoice, please contact
          <br />
          vendoremail.com or Call: 08189954748
          <br />
          Thank You For Your Business!
        </p>
      </div> */}

      <div className="w-full mt-8 text-[#545454]">
        <div className="text-right" style={{ color: Colour }}>
          Subtotal: ₦
          <NumericFormat value={SelectedItems.net_total} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
        </div>
        <div className="text-right mt-2" style={{ color: Colour }}>
          Tax: ₦
          <NumericFormat value={SelectedItems.total_tax_amount} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
        </div>
        <div className="text-right mt-2 font-bold text-lg" style={{ color: Colour }}>
          Total: ₦
          <NumericFormat value={SelectedItems.gross_total} displayType="text" thousandSeparator={true} decimalSeparator="." decimalScale={2} fixedDecimalScale />
        </div>
        {/* Status section */}
        {/* <div className={`text-right mt-2 font-bold text-lg ${statusColor}`}>
          Status: {status}
        </div> */}
        {/* Comment section */}
        {/* {comment && (
          <div className="text-right mt-2" style={{ color: Colour }}>
            <strong>Comment:</strong> {comment}
          </div>
        )} */}
      </div>
    </div>
    <div className="py-8">
    <div className="flex justify-center items-center px-10 md:px-40 ">
  <div>
    <p className="text-center text-lg signature"></p>
    <p className="text-center text-lg signature">{Signature}</p>
    <hr className="divider" />
    <p className="text-center text-sm my-2">Vendor Signature</p>
  </div>
  {/* <div>
    <p className="text-center text-sm">
      {format(new Date(SelectedItems?.due_date), 'dd-MM-yyyy')}
    </p>
    <hr className="divider" />
    <p className="text-center text-sm my-2">Date</p>
  </div> */}
</div>

{/* <div className="flex flex-col px-10 md:px-40 my-4 space-y-6">
  {SelectedItems.workflow.signatories.map((signatur, index) => {
    const signed = SelectedItems.workflow.signatures.find(
      (signature) => signature.role === signatur
    );

    return (
      <div key={index} className="flex justify-between items-end ">
        <div>
          <p className="text-center text-sm">
            {signed ? signed.signer.name : ''}
          </p>
          <hr className="divider" />
          <p className="text-center text-sm my-2">
            {formatText(signatur)}
          </p>
        </div>
        <div>
          <p className="text-center text-sm">
            {signed
              ? format(new Date(signed.date_signed), 'dd-MM-yyyy')
              : ''}
          </p>
          <hr className="divider" />
          <p className="text-center text-sm my-2">Date</p>
        </div>
      </div>
    );
  })}
</div> */}

    </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoicePage;